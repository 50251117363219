// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import {
  ICONS,
  KEYS,
  PERMISSIONS,
  ROLES,
  ROUTES,
  SIZES,
  THEMES,
  TITLES,
  TYPES,
  VARIANTS,
} from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcSortBy,
  AcFormatRole,
  AcGenerateBasicPassword,
  AcGenerateAdvancedPassword,
} from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcCheckbox from '@atoms/ac-checkbox/ac-checkbox.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-add-user-modal',
  CONTENT: 'ac-add-user-modal__content',
};

const AcEditUserModal = ({
  store: { ui, users, companies },
  multistep = {
    roles: true,
    companies: false,
  },
  data,
  submit,
  cancel,
  callback,
}) => {
  let raw_fields = {
    name: data?.name || '',
    email: data?.email || '',
    phone: data?.phone || '',
  };
  let raw_errors = {
    name: data?.name ? null : undefined,
    email: data?.email ? null : undefined,
    phone: data?.phone ? null : undefined,
  };

  const navigate = useNavigate();
  const { can, cannot } = usePermissions();

  const [fields, setFields] = useState(raw_fields);
  const [errors, setErrors] = useState(raw_errors);

  const [newUserObject, setNewUserObject] = useState(data);

  const { hasErrors, handleInputChange, handleInputValidation } =
    useFormActions({
      fields,
      setFields,
      errors,
      setErrors,
    });

  const resetNewUserObject = () => {
    setNewUserObject(data);
  };

  const handleCancel = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
    if (cancel) cancel();
  };

  const handleSubmit = (event) => {
    if (event && event.preventDefault) event.preventDefault();

    handleAssignValuesToNewUser();
  };

  const canAssignCompany = useMemo(() => {
    return multistep?.companies && !data?.is_iqip_user;
  }, [multistep?.companies, data?.is_iqip_user]);

  const handleAssignValuesToNewUser = useCallback(async () => {
    return new Promise(async (resolve) => {
      let obj = newUserObject;

      obj.name = fields.name;
      obj.email = fields.email;
      obj.phone = fields.phone;

      await setNewUserObject(obj);

      if (canAssignCompany) {
        displayAssignCompaniesToUserModal();
      } else if (multistep.roles) {
        displayAssignRolesToUserModal();
      } else {
        await users.update(newUserObject).then((response) => {
          navigate(ROUTES.USER_DETAIL.path.replace(':id', response.id));
        });
      }

      resolve();
    });
  }, [fields]);

  const handleAssignRolesToNewUser = async (selection, collection) => {
    return new Promise(async (resolve) => {
      let obj = newUserObject;

      obj.roles = selection;

      await setNewUserObject(obj);

      await users.update(newUserObject).then((response) => {
        navigate(ROUTES.USER_DETAIL.path.replace(':id', response.id));
      });

      resolve();
    });
  };

  const handleAssignCompaniesToNewUser = async (selection) => {
    return new Promise(async (resolve) => {
      let obj = newUserObject;

      obj.companies = selection;

      await setNewUserObject(obj);

      displayAssignRolesToUserModal();

      resolve();
    });
  };

  const displayAssignCompaniesToUserModal = useCallback(async () => {
    if (cannot(PERMISSIONS.USER.MANAGE_ALL)) return;

    await companies.list({ options: true }, false);
    const collection = companies.current_companies_list;
    const initialSelection = data?.companies.map((company) => company.id);

    ui.multi_select({
      instance: users,
      progress: {
        total_steps: can(PERMISSIONS.USER.MANAGE_ALL) ? 3 : 2,
        current_step: 1,
      },
      collection,
      initialSelection,
      multiple: true,
      alwaysShowSearch: true,
      title: TITLES.ASSIGN_COMPANIES_TO_USER,
      wide: false,
      introduction: '<p>Select 1 or more companies to assign this user to.</p>',
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
      verify_errors: false,
      confirm: {
        label: can(PERMISSIONS.USER.MANAGE_ALL) ? 'Continue' : 'Save user',
        callback: (selection) => {
          return handleAssignCompaniesToNewUser(selection);
        },
      },
      cancel: {
        label: 'Cancel',
        callback: () => {
          resetNewUserObject();
        },
      },
    });
  }, [companies, newUserObject?.companies, data?.companies]);

  const displayAssignRolesToUserModal = useCallback(async () => {
    if (cannot(PERMISSIONS.USER.MANAGE)) return;

    const role_params = {};

    if (data.is_iqip_user) {
      role_params.iqipRoles = 1;
    } else {
      role_params.companyRoles = 1;
    }

    const all_roles = await users.get_roles(role_params);

    const collection = all_roles.map((role) => ({
      id: role.name,
      name: AcFormatRole(role.name),
    }));

    const initialSelection = data?.roles;

    ui.multi_select({
      instance: users,
      progress: {
        total_steps:
          can(PERMISSIONS.USER.MANAGE_ALL) && canAssignCompany ? 3 : 2,
        current_step:
          can(PERMISSIONS.USER.MANAGE_ALL) && canAssignCompany ? 2 : 1,
      },
      collection,
      initialSelection,
      multiple: true,
      alwaysShowSearch: true,
      title: TITLES.ASSIGN_ROLES_TO_USER,
      wide: false,
      introduction: '<p>Select 1 or more roles to assign to this user.</p>',
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
      verify_errors: false,
      confirm: {
        label: 'Save user',
        callback: (selection) => {
          return handleAssignRolesToNewUser(selection, all_roles);
        },
      },
      cancel: {
        label: 'Cancel',
        callback: () => {
          resetNewUserObject();
        },
      },
    });
  }, [newUserObject?.roles, data?.roles, canAssignCompany]);

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT]);
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN]);
  }, []);

  const getNameInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Name',
      name: 'name',
      value: fields.name,
      callback: handleInputChange,
      validation: handleInputValidation,
      focus: true,
    };
  }, [fields, fields.name, data?.name]);

  const getEmailInputOptions = useMemo(() => {
    return {
      type: TYPES.EMAIL,
      label: 'Email address',
      placeholder: 'name@domain.com',
      name: 'email',
      value: fields.email,
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.email, data?.email]);

  const getPhoneInputOptions = useMemo(() => {
    return {
      type: TYPES.PHONE,
      label: 'Phone number',
      placeholder: 'Phone number',
      name: 'phone',
      required: false,
      value: fields.phone,
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.phone, data?.phone]);

  const getCancelButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'Cancel',
      callback: handleCancel,
    };
  });

  const getSubmitButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.ALPHA,
      disabled: errors && hasErrors,
      title: 'Save',
      callback: handleSubmit,
    };
  }, [fields, errors, hasErrors]);

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        <form method={'post'} onSubmit={handleSubmit}>
          <AcContainer fluid>
            <AcRow>
              <AcColumn>
                <AcTextInput {...getNameInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcTextInput {...getEmailInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcTextInput {...getPhoneInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow className={'h-margin-top-20'}>
              <AcColumn
                xxs={12}
                xs={7}
                sm={6}
                className={'h-text--align-left h-flex-v-align-center'}
              >
                <AcButton {...getCancelButtonOptions}>
                  <span>Cancel</span>
                </AcButton>
              </AcColumn>

              <AcColumn
                xxs={12}
                xs={5}
                sm={6}
                className={'h-text--align-right'}
              >
                <AcButton {...getSubmitButtonOptions}>
                  <span>{multistep ? 'Continue' : 'Save'}</span>
                </AcButton>
              </AcColumn>
            </AcRow>
          </AcContainer>
        </form>
      </div>
      {users.is_busy && <AcLoader loading={true} cover />}
    </div>
  );
};

export default withStore(observer(AcEditUserModal));
