// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Data
import { COUNTRIES } from '@data/countries.data';

// Imports => Constants
import {
	KEYS,
	PERMISSIONS,
	ROLES,
	THEMES,
	TITLES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilities
import { AcUUID, AcIsSet, AcIsNull, AcIsBoolean } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
	MAIN: 'ac-edit-user-modal',
	CONTENT: 'ac-edit-user-modal__content',
};

let delay = null;

const AcEditOperatorCredentialsModal = ({
	store: { ui },
	data,
	submit,
	callback,
}) => {
	const { can, cannot } = usePermissions();

	let raw_fields = {
		username: data?.basic_operator_username || '',
		password: data?.basic_operator_password || '',
	};
	let raw_errors = {
		username: data?.basic_operator_username ? null : undefined,
		password: data?.basic_operator_password ? null : undefined,
	};

	const [fields, setFields] = useState(raw_fields);
	const [errors, setErrors] = useState(raw_errors);

	const { hasErrors, handleInputChange, handleInputValidation } =
		useFormActions({
			fields,
			setFields,
			errors,
			setErrors,
		});

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();

		if (submit) {
			submit(data, fields).then(() =>
				ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
			);
		}
	};

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const getJeanLutzUserNameInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Operator Username',
			name: 'username',
			value: fields.username,
			callback: handleInputChange,
			validation: handleInputValidation,
			disabled: cannot(PERMISSIONS.COMPANY.UPDATE),
			focus: true,
		};
	}, [fields, fields.username]);

	const getBasicPasswordInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Operator password',
			name: 'password',
			value: fields.password,
			callback: handleInputChange,
			validation: handleInputValidation,
			disabled: cannot(PERMISSIONS.COMPANY.UPDATE),
		};
	}, [fields, fields.password]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			disabled: hasErrors,
			title: 'Save changes',
			callback: handleSubmit,
		};
	}, [fields, errors, hasErrors]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				{fields && (
					<form method={'post'} onSubmit={handleSubmit}>
						<AcContainer fluid>
							<AcRow>
								<AcColumn>
									<AcTextInput {...getJeanLutzUserNameInputOptions} />
								</AcColumn>
							</AcRow>

							<AcRow>
								<AcColumn>
									<AcTextInput {...getBasicPasswordInputOptions} />
								</AcColumn>
							</AcRow>

							<AcRow className={'h-margin-top-20'}>
								<AcColumn
									xxs={12}
									xs={7}
									sm={6}
									className={'h-text--align-left h-flex-v-align-center'}
								>
									<AcButton {...getCancelButtonOptions}>
										<span>Cancel</span>
									</AcButton>
								</AcColumn>

								<AcColumn
									xxs={12}
									xs={5}
									sm={6}
									className={'h-text--align-right'}
								>
									<AcButton {...getSubmitButtonOptions}>
										<span>Save changes</span>
									</AcButton>
								</AcColumn>
							</AcRow>
						</AcContainer>
					</form>
				)}
			</div>
		</div>
	);
};

export default withStore(observer(AcEditOperatorCredentialsModal));
